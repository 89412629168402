<template>
	<div class="hotel-detail">
		<ul class="reserve-flows flex-start flexbox">
			<li :class="(item.current == true ? 'current ' : '') + 'ticket-box  flexbox'" v-for="(item, index) in progress" :key="index">
				<span class="flows-line"></span>
				<a class="flows-text">{{ item.name }}</a>
			</li>
		</ul>
		<div class="pro-public-detail hotel-detail" v-if="HotelDetailInfo != ''">
			<div class="product-about">
				<div class="clearfix">
					<div class="left-txt">
						<p class="hotle-name">{{ HotelDetailInfo.HotelName }}({{ HotelDetailInfo.HotelEName }})</p>
						<p class="mt5 mb5">
							<el-rate v-model="HotelDetailInfo.Star" disabled show-score text-color="#ff9900" score-template="{value}" void-icon-class="gray9"></el-rate>
						</p>
						<p class="ft14 gray3">
							地址：{{ HotelDetailInfo.Address }}
							<!-- <a
								href="javascript:;"
								:data-latitude="HotelDetailInfo.Latitude"
								:data-longitude="HotelDetailInfo.Longitude"
								data-target="#hotel-map-pop"
								data-toggle="modal"
								class="check-map"
								@click="viewMap"
							>
								查看地图
							</a> -->
						</p>
						<p class="service-box mt5">
							<span class="iconfont icon-wifi icon-i"></span>
							<span class="iconfont icon-i">p</span>
						</p>
					</div>
				</div>
				<div class="flexbox">
					<superslide :options="options" class="slideBox foucebox flexbox">
						<div class="bd">
							<ul>
								<!-- <li class="showDiv" v-for="(item, index) in HotelDetailInfo.ImageFiles" :key="index">
									<a href="javascript:;"><el-image :src="item" :preview-src-list="HotelDetailInfo.ImageFiles"/></a>
								</li> -->
								<li class="showDiv">
									<a href="javascript:;">
										<el-image
											:fit="fit"
											:src="HotelDetailInfo.ImageFiles[imgidx]"
											:preview-src-list="HotelDetailInfo.ImageFiles"
											style="width:100%;height:100%"
											:error="bindImgError(imgidx)"
										></el-image>
									</a>
								</li>
							</ul>
						</div>
						<div class="hd">
							<ul class="clearfix">
								<li class="on" v-for="(item, index) in HotelDetailInfo.ImageFiles" :key="index">
									<el-image :data-idx="index" @mouseover="bindImgidx" :src="item" :preview-src-list="HotelDetailInfo.ImageFiles" style="width:100%;height:100%;">
										<div slot="error" class="image-slot"><el-image src="https://3gapitest.yiqifei.com/Content/images/hotel/hotelnopic.jpg"/></div>
									</el-image>
								</li>
							</ul>
						</div>
						<!-- <a class="txt" href="javascript:;" @click="viewPic">查看所有的图片</a> -->
					</superslide>
					<div class="hotel-locate-map">
						<!-- <div class="loacatebox">
							<div class="hotel-name">酒店</div>
							<div class="imgbox">1</div>
						</div> -->

						<iframe
							v-if="showInitMap"
							id="itomFr"
							name="leftframe"
							:src="mapsUrl"
							marginwidth="10"
							marginheight="10"
							frameborder="no"
							width="100%"
							scrolling="yes"
							height="100% "
						></iframe>
					</div>
				</div>
			</div>
		</div>

		<div class="detail-con">
			<div class="clearfix tagbox">
				<div class="tag-content fl">
					<a href="javascript:;" class="current" @click="bindScrollIntoView('#divHotelRoomList')">房型预订</a>
					<a href="javascript:;" @click="bindScrollIntoView('#divHotelDesc')">酒店概述</a>
				</div>
			</div>

			<div class="pd10 hotel-indexv2 detail-two hotel-list-box pt20 pl20" style="background: #fff;margin-left: 0px;width: initial;">
				<SearchReply @bindSearchHotel="bindSearchHotel" :HotelSearchInfo="HotelSearchInfo"></SearchReply>

				<el-row type="flex" justify="start" v-if="filterInfo != ''" style="flex-wrap:wrap">
					<span class="bedtype">床型</span>
					<span
						:class="item.name == filterStr.Bed ? 'metter active' : 'metter'"
						v-for="(item, index) in filterInfo.filterBeds"
						:key="index"
						@click="bindSelectFilter(item, 'Bed')"
					>
						{{ item.name }}
					</span>
					<span class="bedtype" style="margin-left:30px;">早餐</span>
					<span
						:class="item.name == filterStr.Breakfast ? 'metter active' : 'metter'"
						v-for="(item, index) in filterInfo.filterBreakfasts"
						:key="index"
						@click="bindSelectFilter(item, 'Breakfast')"
					>
						{{ item.name }}
					</span>
					<span class="bedtype" style="margin-left:30px;">支付方式</span>
					<span
						:class="item.name == filterStr.PayType ? 'metter active' : 'metter'"
						v-for="(item, index) in filterInfo.filterPayTypes"
						:key="index"
						@click="bindSelectFilter(item, 'PayType')"
					>
						{{ item.name }}
					</span>
				</el-row>
				<el-row type="flex" justify="start" class="mt15 mb15">
					<span class="bedtype">取消方式</span>
					<span
						:class="item.name == filterStr.CancelPolicy ? 'metter active' : 'metter'"
						v-for="(item, index) in filterInfo.filterCancelPolicys"
						:key="index"
						@click="bindSelectFilter(item, 'CancelPolicy')"
					>
						{{ item.name }}
					</span>
				</el-row>
			</div>

			<yellowLoading v-if="isShowloading"></yellowLoading>
			<flightNoData v-if="isNoData" msg="酒店"></flightNoData>

			<div v-if="Recommendations.length > 0">
				<el-row type="flex" class="room-b" v-for="(item, index) in Recommendations" :key="index">
					<div class="favorateroom-con">推荐</div>
					<div class="w40-room-metter" @click="bindShowDetailInfo(item)" style="border-right:1px solid #ececec">
						<el-image :src="item.CoverImg" style="width:120px;height:90px"></el-image>
						<div class="room-biaozhun">{{ item.RoomPriceInfos[0].RoomName }}</div>
					</div>
					<el-row class="w60-room-type" style="width:82%">
						<el-row type="flex" align="middle" justify="space-between" style="padding-top:30px">
							<el-row type="flex" align="middle" justify="space-between" style="width: 55%;;">
								<el-col :span="2">
									<span class="iconfont icon-yqfwodeshi icon-member" style="margin-right: 10px;" v-for="adtqty in item.ADTQty" :key="adtqty"></span>
								</el-col>
								<el-col :span="12" style="margin-right:110px">
									<div>
										<el-dropdown :hide-on-click="false">
											<span class="el-dropdown-link ">{{ item.RoomPriceInfos[0].BedListStr }}</span>
											<!-- <el-dropdown-menu slot="dropdown" style="width:120px;text-align: center;">1张大床 (1.8米)</el-dropdown-menu> -->
										</el-dropdown>
									</div>
								</el-col>
								<el-col :span="8">
									<div>
										<el-dropdown :hide-on-click="false">
											<span class="el-dropdown-link active-green">{{ item.RoomPriceInfos[0].BreakfastStr }}</span>
											<el-dropdown-menu slot="dropdown" style="width:120px;text-align: center;" v-if="item.RoomPriceInfos[0].HotelBreakfast != null">
												{{ item.RoomPriceInfos[0].HotelBreakfast.BreakfastDesc }}
											</el-dropdown-menu>
										</el-dropdown>
									</div>
								</el-col>
								<el-col :span="8">
									<div class="ft14" style="position: relative;left: 9px;">
										<div>{{ item.RoomPriceInfos[0].IsNoSmoking ? '禁烟' : '部分禁烟' }}</div>
										<!-- <div>有窗</div> -->
									</div>
								</el-col>
								<el-col :span="6" style="position: relative;left: 19px;">
									<div>
										<div>
											<el-dropdown :hide-on-click="false">
												<span class="el-dropdown-link black-active">{{ item.RoomPriceInfos[0].CancelPolicyStr }}</span>
												<el-dropdown-menu slot="dropdown" style="padding:10px">
													{{ item.RoomPriceInfos[0].HotelCancelPolicy.PolicyDetails }}
												</el-dropdown-menu>
											</el-dropdown>
										</div>
										<div>
											<el-dropdown :hide-on-click="false" v-if="item.RoomPriceInfos[0].ConfirmType == 1">
												<span class="el-dropdown-link active-green">立即确认</span>
												<el-dropdown-menu slot="dropdown" style="padding:10px">预订此房型后可快速确认订单。</el-dropdown-menu>
											</el-dropdown>
										</div>
									</div>
								</el-col>

								<div>
									<!-- <el-dropdown :hide-on-click="false">
									<span class="el-dropdown-link"><span class="promotion-ctrip-tag">礼</span></span>
									<el-dropdown-menu slot="dropdown" style="padding:10px">预订此房型后可快速确认订单。</el-dropdown-menu>
								</el-dropdown> -->
									<!-- <el-dropdown :hide-on-click="false">
									<span class="el-dropdown-link"><span class="promotion-ctrip-tag">天天特价</span></span>
									<el-dropdown-menu slot="dropdown" style="padding:10px">预订此房型后可快速确认订单。</el-dropdown-menu>
								</el-dropdown> -->
								</div>
							</el-row>
							<el-row type="flex">
								<el-dropdown :hide-on-click="false">
									<div class="price-display" style="color: #fe6156;">￥{{ item.RoomPriceInfos[0].TotalAmount }}</div>
									<el-dropdown-menu slot="dropdown" style="width:350px;text-align: center;">
										<el-dropdown-item>
											<el-row type="flex" justify="space-between">
												<div style="line-height: 1.5;">
													<div>价格详情</div>
													<div>{{ item.RoomPriceInfos[0].CancelPolicyStr }}</div>
													<div>{{ item.RoomPriceInfos[0].BreakfastStr }}</div>
												</div>
											</el-row>
										</el-dropdown-item>
										<el-dropdown-item>
											<el-row type="flex" justify="space-between">
												<div>每天每晚均价</div>
												<div>￥{{ item.RoomPriceInfos[0].AveragePrice }}</div>
											</el-row>
										</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>

								<div>
									<el-button
										type="warning"
										style="background-color: #ff9500;"
										:data-id="item.ID"
										:data-roomid="item.RoomPriceInfos[0].RoomPriceID"
										@click="bindBooking"
									>
										预订
									</el-button>
									<div class="paytype">{{ item.RoomPriceInfos[0].PayType }}</div>
								</div>
							</el-row>
						</el-row>
					</el-row>
				</el-row>
			</div>

			<div class="mb5" v-for="(item, index) in RoomPriceList" :key="index">
				<el-row type="flex" class="room-b2" align="middle">
					<div class="w40-room-metter" style="margin-bottom: 0px;margin-right: 0px;" @click="bindShowDetailInfo(item)">
						<el-image style="width:120px;height:90px;" :src="item.CoverImg"></el-image>
						<div class="room-biaozhun">
							{{ item.Name }}
							<!-- {{ item.recommend }} -->
						</div>
					</div>
					<div style="width:83%;border-left:1px solid #ececec" class="right-bor">
						<div v-for="(item_price, index2) in item.RoomPriceInfos" :key="index2">
							<el-row class="w60-room-type w60-room-active" style="padding-left: 20px;padding-bottom:30px;" v-if="currentMoreItem == item.ID || index2 <= 2">
								<el-row type="flex" align="middle" justify="space-between" style="padding-top:30px">
									<el-row type="flex" align="middle" justify="space-between" style="width: 100%;">
										<el-col :span="3">
											<span class="iconfont icon-yqfwodeshi icon-member" style="margin-right: 10px;" v-for="adtqty in item.ADTQty" :key="adtqty"></span>
										</el-col>

										<el-col :span="8" style="margin-right:86px">
											<div>
												<el-dropdown :hide-on-click="false">
													<span class="el-dropdown-link">{{ item_price.BedListStr }}</span>
													<!-- <el-dropdown-menu slot="dropdown" style="width:120px;text-align: center;">1张大床 (1.8米)</el-dropdown-menu> -->
												</el-dropdown>
											</div>
										</el-col>
										<el-col :span="6">
											<div>
												<el-dropdown :hide-on-click="false">
													<span class="el-dropdown-link active-green">{{ item_price.BreakfastStr }}</span>
													<el-dropdown-menu slot="dropdown" style="width:120px;text-align: center;" v-if="item_price.HotelBreakfast != null">
														{{ item_price.HotelBreakfast.BreakfastDesc }}
													</el-dropdown-menu>
												</el-dropdown>
											</div>
										</el-col>
										<el-col :span="6">
											<div class="ft14">
												<div>{{ item.IsNoSmoking ? '禁烟' : '部分禁烟' }}</div>
												<!-- <div>{{item.IsNoSmoking}}</div> -->
											</div>
										</el-col>
										<el-col :span="8">
											<div>
												<div>
													<el-dropdown :hide-on-click="false">
														<span class="el-dropdown-link black-active">{{ item_price.CancelPolicyStr }}</span>
														<el-dropdown-menu slot="dropdown" style="padding:10px">{{ item_price.HotelCancelPolicy.PolicyDetails }}</el-dropdown-menu>
													</el-dropdown>
												</div>
												<div>
													<el-dropdown :hide-on-click="false" v-if="item_price.ConfirmType == 1">
														<span class="el-dropdown-link active-green">立即确认</span>
														<el-dropdown-menu slot="dropdown" style="padding:10px">预订此房型后可快速确认订单。</el-dropdown-menu>
													</el-dropdown>
													<!-- <el-dropdown :hide-on-click="false" v-else>
															<span class="el-dropdown-link active-green">延迟确认</span>
															<el-dropdown-menu slot="dropdown" style="padding:10px">预订此房型后可快速确认订单。</el-dropdown-menu>
														</el-dropdown> -->
												</div>
											</div>
										</el-col>
										<el-col :span="12">
											<div>
												<el-dropdown :hide-on-click="false" v-show="false">
													<span class="el-dropdown-link"><span class="promotion-ctrip-tag">礼</span></span>
													<el-dropdown-menu slot="dropdown" style="padding:10px">预订此房型后可快速确认订单。</el-dropdown-menu>
												</el-dropdown>
											</div>
										</el-col>
									</el-row>
									<el-row type="flex">
										<el-dropdown :hide-on-click="false">
											<div class="price-display" style="color: #fe6156;">￥{{ item_price.TotalAmount }}</div>
											<el-dropdown-menu slot="dropdown" style="width:350px;text-align: center;">
												<el-dropdown-item>
													<el-row type="flex" justify="space-between">
														<div style="line-height: 1.5;">
															<div>价格详情</div>
															<div>{{ item_price.CancelPolicyStr }}</div>
															<div>{{ item_price.BreakfastStr }}</div>
														</div>
													</el-row>
												</el-dropdown-item>
												<el-dropdown-item>
													<el-row type="flex" justify="space-between">
														<div>每天每晚均价</div>
														<div>￥{{ item_price.AveragePrice }}</div>
													</el-row>
												</el-dropdown-item>
												<!-- <el-dropdown-item>
														<el-row type="flex" justify="space-between">
															<div>天天特价</div>
															<div>-￥92</div>
														</el-row>
													</el-dropdown-item>
													<el-dropdown-item>
														<el-row type="flex" justify="space-between">
															<div>可领返现</div>
															<div>返￥13</div>
														</el-row>
													</el-dropdown-item> -->

												<el-dropdown-item>
													<el-row type="flex" justify="end" style="border-top:1px solid #ececec">
														<div>￥{{ item_price.TotalAmount }}</div>
													</el-row>
												</el-dropdown-item>
											</el-dropdown-menu>
										</el-dropdown>
										<div>
											<el-button
												type="warning"
												style="background-color: #ff9500;"
												:data-id="item.ID"
												:data-roomid="item.RoomPriceInfos[0].RoomPriceID"
												@click="bindBooking"
											>
												预订
											</el-button>
											<div class="paytype">{{ item_price.PayType }}</div>
										</div>
									</el-row>
								</el-row>
							</el-row>
						</div>
						<el-row type="flex" justify="end" style="padding: 28px 20px;" class="ft14" v-if="item.RoomPriceInfos.length > 3">
							<div class="saleroomlist-showMore" @click="bindMoreItemClick" :data-id="item.ID" v-if="currentMoreItem != item.ID">
								<span>展开</span>
								<span class="iconfont icon-xiala icon-xiangxia11"></span>
							</div>
							<div v-else class="saleroomlist-showMore" @click="bindMoreItemClick" :data-id="item.ID">
								<span>收起</span>
								<span class="iconfont icon-xiala-copy icon-xiangshang12"></span>
							</div>
						</el-row>
					</div>
				</el-row>
			</div>

			<div class="tab-contents" id="divHotelDesc">
				<div class="hd">酒店详情</div>
				<div class="bd" v-if="HotelDetailInfo != ''">
					<div class="hotel-text">
						<p>酒店简介</p>
						<p v-html="HotelDetailInfo.Intro"></p>
					</div>
					<el-row class="hotel-text" type="flex">
						<p class="mr10">酒店设施</p>
						<!-- <p>
							<span class="iconfont icon-wifi icon-i"></span>
							<span class="iconfont icon-tingche icon-i"></span>
						</p> -->
					</el-row>
					<el-row class="hotel-text" type="flex">
						<div>
							<p class="mr10" v-for="(item, index) in HotelDetailInfo.FacilityList" :key="index">{{ item.FacilityName }}</p>
							<!-- <span class="text">免费Wifi、</span>
							<span class="text">免费停车、</span>
							<span class="text">健身房、</span> -->
						</div>
					</el-row>
				</div>
			</div>
		</div>
		<!-- 控制显示隐藏  ComfirmTicket-->
		<desModal :titleRight="ComfirmTicket" :widthNum="ComfirmTicket" :footerNone="false" :showClose="true">
			<div class="text-c">
				<img src="../../assets/serch_loading.gif" class="img" style="width: 60px;position:relative;top:4px;" />
				<p class="ft16 fn mt20 pb20">正在验证房价，此过程需要几秒钟时间...</p>
			</div>
		</desModal>

		<!-- <slideModal :picShow="picShow" @clickDelAction="clickDel" :piclist="HotelDetailInfo.ImageFiles"></slideModal> -->
		<mapModal :mapShow="mapShow" @clickDelAction="clickDelmap"></mapModal>
		<desModal :titleRight="showDetail == true" widthNum="28%" title="详情" @clickDelAction="delDetail">
			<div class="text-center">
				<div><el-image style="width: 100%;height: 300px;" :src="roomDetailInfo.CoverImg"></el-image></div>
				<p>{{ roomDetailInfo.Intro }}</p>
				<p>{{ roomDetailInfo.Internet }}</p>
			</div>
		</desModal>
	</div>
</template>

<script>
import SearchReply from './component/SearchReply.vue';
import yellowLoading from '../../components/yellowLodaing.vue';
import flightNoData from '../../components/flightNoData.vue';
// import slideModal from './component/slideModal.vue';
import mapModal from './component/mapModal.vue';
import desModal from '../../components/desModal.vue';

import { adults, childrens, HotelSearchInfo } from '@/data/staticData';
import { getHotelSearchInfo, setHotelSearchInfo } from '@/utils/localdatas';

var util = require('@/utils/util.js');
var hotelApi = require('@/api/hotelApi.js');
var that;
var searchCount = 0;
export default {
	components: {
		SearchReply,
		yellowLoading,
		flightNoData,
		// slideModal,
		mapModal,
		desModal
	},
	data() {
		return {
			ComfirmTicket: false, //验价提醒
			showDetail: false,
			picShow: false,
			mapShow: false,
			options: {
				mainCell: '.bd ul',
				autoPlay: true,
				titCell: '.hd ul li'
			},
			progress: [
				{
					name: '选择酒店',
					current: true
				},
				{
					name: '选择房型',
					current: true
				},
				{
					name: '填写资料'
				},
				{
					name: '确认资料'
				},
				{
					name: '提交订单'
				}
			],

			isShowloading: true,
			isNoData: false,
			HotelDetailInfo: '',
			ShowHotelRooms: [],
			HotelID: '',
			RoomPriceList: [],
			currentItem: '',
			currentMoreItem: '',
			Recommendations: [],
			imgidx: 0,
			fit: 'fill',
			roomDetailInfo: '',
			HotelSearchInfo: HotelSearchInfo,
			filterInfo: '',
			filterStr: {
				Bed: '',
				Breakfast: '',
				PayType: '',
				CancelPolicy: ''
			},
			mapsUrl: '',
			mapInUrl: '',
			showInitMap: false,
			inCity: ''
		};
	},
	created() {
		console.log(this.$route.query);
		that = this;
		let HotelID = this.$route.query.hotelid;

		var HotelSearchInfo = getHotelSearchInfo();
		that.inCity = HotelSearchInfo.Citycode;
		that.HotelSearchInfo = HotelSearchInfo;
		that.dates = [HotelSearchInfo.CheckInDateStr, HotelSearchInfo.CheckOutDateStr];

		if (!util.isNullOrEmpty(HotelID)) {
			that.HotelID = HotelID;
			getHotelDetail(HotelID);
		} else {
			that.isNoData = true;
		}
		$(window).scrollTop("0");
	},
	methods: {
		bindImgError(imgIdx) {
			console.log(imgIdx);
		},
		//
		bindScrollIntoView(id) {
			document.querySelector(id).scrollIntoView(true);
		},
		//查看大图
		bindImgidx(e) {
			var idx = e.currentTarget.dataset.idx;
			that.imgidx = idx;
		},
		//筛选
		bindSelectFilter(item, filterType) {
			switch (filterType) {
				case 'Bed':
					if (that.filterStr.Bed != '' && that.filterStr.Bed == item.name) {
						that.filterStr.Bed = '';
					} else {
						that.filterStr.Bed = item.name;
					}
					break;
				case 'Breakfast':
					if (that.filterStr.Breakfast != '' && that.filterStr.Breakfast == item.name) {
						that.filterStr.Breakfast = '';
					} else {
						that.filterStr.Breakfast = item.name;
					}

					break;
				case 'PayType':
					if (that.filterStr.PayType != '' && that.filterStr.PayType == item.name) {
						that.filterStr.PayType = '';
					} else {
						that.filterStr.PayType = item.name;
					}
					break;
				case 'CancelPolicy':
					if (that.filterStr.CancelPolicy != '' && that.filterStr.CancelPolicy == item.name) {
						that.filterStr.CancelPolicy = '';
					} else {
						that.filterStr.CancelPolicy = item.name;
					}

					break;
			}
			getFilterHotelRoomPriceInfo();
		},
		//更多
		bindItemClick(e) {
			var thisitem = e.currentTarget.dataset.id;
			console.log(thisitem);

			if (that.currentItem != '' && that.currentItem == thisitem) {
				thisitem = '';
			}
			that.currentItem = thisitem;
		},
		//更多
		bindMoreItemClick(e) {
			var thisitem = e.currentTarget.dataset.id;
			if (that.currentMoreItem != '' && that.currentMoreItem == thisitem) {
				thisitem = '';
			}
			that.currentMoreItem = thisitem;
			// document.querySelector('#flight_' + thisitem + '').scrollIntoView(true);
		},
		//预定
		bindBooking(e) {
			that.ComfirmTicket = true;
			var id = e.currentTarget.dataset.id;
			var roomid = e.currentTarget.dataset.roomid;
			console.log(id);
			console.log(roomid);

			var parm = {
				ID: id,
				RoomID: roomid,
				hotelinfojson: JSON.stringify(that.HotelDetailInfo)
			};
			hotelApi.GetBookHotelRoom(parm, function(result) {
				that.ComfirmTicket = false;
				// console.log(result);;
				if (result.code == 0) {
					that.$router.push({
						path: '/Hotel/booking'
					});
				} else if (result.code == -3) {
					//验价失败
					that.$confirm(result.msg, {
						distinguishCancelAndClose: true,
						confirmButtonText: '重新选择',
						// cancelButtonText: '继续预订'
					})
						.then(() => {
							reSearch();
						})
						// .catch(action => {
						// 	that.$router.push({
						// 		path: '/Hotel/booking'
						// 	});
						// });
				} else {
					that.$alert(result.msg, {
						confirmButtonText: '确定',
						callback: action => {
							reSearch();
						}
					});
				}
			});
		},
		viewPic(e) {
			// alert(1);
			this.picShow = true;
		},
		clickDel() {
			this.picShow = false;
		},
		clickDelmap() {
			this.mapShow = false;
		},
		viewMap() {
			this.mapShow = true;
		},
		//房型详情
		bindShowDetailInfo(item) {
			console.log(item);
			// let roomDetailInfo='';
			that.roomDetailInfo = item;
			this.showDetail = true;
		},
		delDetail() {
			this.showDetail = false;
		},
		//重新搜索
		bindSearchHotel(e) {
			console.log(that.HotelSearchInfo);
			// 	that.isNoData = false;
			// 	that.isShowLoading = true;

			// 	that.HotelListInfo = [];
			// 	that.pageTotal = 0;
			hotelApi.GetHotelSearch({ hotelsearchinfo: JSON.stringify(that.HotelSearchInfo) }, function(result) {
				// console.log(result);;
				if (result.code == 0) {
					that.HotelSearchInfo = result.data.HotelSearchInfo;
					if (that.inCity == that.HotelSearchInfo.Citycode) {
						getHotelDetail(that.HotelID);
					} else {
						that.$router.push({
							path: '/Hotel/list'
						});
					}
					// console.log(HotelSearchInfo);
					// getHotelListAsyncId();
				} else {
					that.$message.error(result.msg);
				}
			});
		}
	}
};

function reSearch() {
	getHotelDetail(that.HotelID);
	// GetHotelRoomPriceAsyncId(that.HotelID);
}

function getHotelDetail(HotelID) {
	hotelApi.GetHotelDetailInfo(
		{
			HotelID: HotelID
		},
		function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.HotelDetailInfo = result.data.HotelDetailInfo;
				that.ShowHotelRooms = result.data.ShowHotelRooms;
				initTXMap();
				GetHotelRoomPriceAsyncId(HotelID);
			}
		}
	);
}

function GetHotelRoomPriceAsyncId(HotelID) {
	that.isShowloading = true;
	that.isNoData = false;
	searchCount = 0;
	hotelApi.GetHotelRoomAsyncId(
		{
			HotelID: HotelID
		},
		function(result) {
			// console.log(result);;
			if (result.code == 0) {
				GetHotelRoomPrice(result.data.AsyncId);
			} else {
				that.isNoData = true;
			}
		}
	);
}

function GetHotelRoomPrice(AsyncId) {
	searchCount++;
	hotelApi.GetHotelRoomListByAsyncId(
		{
			AsyncId: AsyncId,
			searchCount: searchCount,
			HotelID: that.HotelID,
			ShowHotelRoomsJSON: JSON.stringify(that.ShowHotelRooms)
		},
		function(result) {
			// console.log(result);;
			let isComplete;

			// that.isShowloading=false;
			if (result.code == 0) {
				that.RoomPriceList = result.data.HotelRoomList;
				that.Recommendations = result.data.Recommendations;
				that.filterInfo = result.data.filterInfo;
				isComplete = result.data.isComplete;
			} else {
				isComplete = true;
			}

			if (!isComplete) {
				setTimeout(function() {
					GetHotelRoomPrice(AsyncId);
				}, 1500);
			} else {
				if (that.RoomPriceList.length == 0) {
					that.isNoData = true;
				}
				// getFilterHotelRoomPriceInfo();
				that.isShowloading = false;
				// if (searchFlightCount == 0) {
				// 	that.isNoData = true;
				// } else {
				// 	getFilterIntFlightInfo();
				// }
			}
		}
	);
}
//筛选
function getFilterHotelRoomPriceInfo() {
	let parm = {
		filterBed: that.filterStr.Bed,
		filterBreakfast: that.filterStr.Breakfast,
		filterCancelPolicy: that.filterStr.CancelPolicy,
		filterPayType: that.filterStr.PayType
	};
	console.log(parm);
	// return;
	hotelApi.GetFilterHotelRoomPriceInfo(parm, function(result) {
		// console.log(result);;
		// that.isShowloading=false;
		if (result.code == 0) {
			that.RoomPriceList = result.data.HotelRoomList;
		} else {
		}
	});
}

function initTXMap() {
	let coordinates = [];
	let HotelDetailInfo = that.HotelDetailInfo;
	let coordinate = {
		Latitude: HotelDetailInfo.Latitude,
		Longitude: HotelDetailInfo.Longitude,
		name: HotelDetailInfo.HotelName,
		Address: HotelDetailInfo.Address
	};

	coordinates.push(coordinate);

	// let mapUrl="http://fhlocal.yiqifei.com/MapAPI/Index?coordinatesJSON="+JSON.stringify(coordinates);
	let coordinatesJSON = JSON.stringify(coordinates);

	that.mapsUrl = util.mapApiUrl + '?coordinatesJSON=' + coordinatesJSON;
	console.log(that.mapsUrl);
	that.showInitMap = true;
}
</script>

<style lang="scss">
@import '../../style/Hotel/hotel.scss';

.product-about {
	padding: 25px;
	background: #fff;
	margin-top: 20px;
}
</style>
